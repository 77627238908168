<template>
  <div class="batchBox">
    <el-row
      class="accountCon"
    >
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 分类信息
          <img src="@/assets/images/triangle.png" alt="">
        </div>
      </el-col>
      <el-col class="rightCon" :span="20">
        <el-form
          ref="detail"
          label-position="right"
          label-width="180px"
          :rules="rules"
          :model="detail"
        >

          <el-form-item label="分类名称:" prop="name">
            <el-input
              v-model="detail.name"
              class="w300"
              maxlength="150"
              placeholder="请输入分类名称"
              show-word-limit
            />
          </el-form-item>
          <el-button
            type="primary"
            size="mini"
            class="mainBtn"
            @click="submitForm('detail')"
          >完成，提交</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { createCategory,categoryDetail,updateCategory } from '@/api/goods';
class Detail {
    name='';
}
class Rules {
  name = [
    { required: true, message: '请填写分类名称', trigger: 'blur' }
  ];
}
export default {
  props: {

  },

  data () {
    return {
      detail: new Detail(),
      rules: new Rules(),
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    if(this.$route.query.id )this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail(){
       categoryDetail({id:this.$route.query.id} ).then(res => {
        this.detail = res;
      })
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$route.query.id?this.hint('更新'):this.hint('新建')
             
        } else {
          return false
        }
      })
    },
    // 提示
    hint(text){
       this.$confirm(`确定${text}此分类吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.confirm()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    // 确认提交
     confirm() {
       if(this.$route.query.id ){
         updateCategory({...this.detail} ).then(res => {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.$router.push('./classifyList')
        })
       }else{
         createCategory({...this.detail} ).then(res => {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.$router.push('./classifyList')
        })
       }
       
    
    }
  }
}
</script>

<style lang="scss" scoped>
.accountCon{
    background: #fff;
    .leftCon{
      background: #fcfcfc;
      border-right: 1px solid #eee;
      position: absolute;
    height: 100%;
      >div{
        line-height: 48px;
        color: #fff;
        background: #9EA7B4;
        width: 100%;
        padding-left: 80px;
        font-size: 14px;
        box-sizing: border-box;
        img{
          position: absolute;
          right: -30px;
          width: 40px;
          height: 48px;
        }
      }
      .label1{
        position: absolute;
        top: 40px;
      }
      .label2{
        position: absolute;
        top: 270px;
      }
    }
    .rightCon{
      padding: 40px 0 0 300px;
      .btn{
        margin: 20px 0 50px  80px;
      }
      .mainBtn{
        margin: 30px 0 30px 260px;
      }
      .phoneTip{
        font-size: 12px;
        color: #999;
      }
    }
}
</style>
